.explore-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.explore-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

