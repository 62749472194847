/* Full space background with stars */
.ask-astronaut-container { /* You can use a local space background image if needed */
    background-size: cover;
    background-position: center;
    color: #ffffff; /* White text for readability */
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Orbitron', sans-serif; /* Adding a futuristic space-themed font */
  }
  
  .space-header {
    font-size: 3rem;
    margin-bottom: 20px;
    text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.6); /* Glowing effect */
  }
  
  .space-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .space-input {
    width: 350px;
    height: 30px;
    padding: 10px;
    border-radius: 8px;
    border: none;
    margin-bottom: 10px;
    font-size: 1rem;
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
    color: #fff;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.4); /* Glowing effect */
  }
  
  .space-input::placeholder {
    color: #ccc;
  }
  
  .space-submit-btn {
    background-color: rgba(0, 128, 255, 0.7);
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 0 0 15px rgba(0, 128, 255, 0.8); /* Neon glow effect */
    transition: all 0.3s ease;
  }
  
  .space-submit-btn:hover {
    background-color: rgba(0, 128, 255, 1);
    box-shadow: 0 0 20px rgba(0, 128, 255, 1); /* Brighter on hover */
  }
  
  .response-box {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8); /* Dark transparent box for the astronaut response */
    padding: 30px; /* Increased padding for larger response box */
    border-radius: 12px;
    margin-top: 20px;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.3); /* Soft glow */
    max-width: 700px; /* Increased max width */
  }
  
  .astronaut-img {
    width: 250px;
    height: 200px;
    margin-right: 20px;
  }
  
  .answer-text h2 {
    margin: 0;
    color: #00ffcc;
    font-size: 1.8rem;
    text-shadow: 0 0 10px #00ffcc; /* Glowing text */
  }
  
  .answer-text p {
    color: #dcdcdc; /* Grayish text */
    font-size: 1.2rem; /* Slightly larger text */
  }
  
  .error-box {
    color: #ff6961;
    background-color: rgba(255, 0, 0, 0.1);
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.3);
    max-width: 600px;
    text-align: center;
  }
  